import Str from "@/helpers/Str";

import AppBreadcrumbs from "@/components/AppBreadcrumbs";
import LectureForm from "@/components/methodist/courses/show/LectureForm";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  mixins: [showErrorMessageMixin],
  components: { AppBreadcrumbs, LectureForm },
  data() {
    return {
      lectureData: {
        name: "",
        content: "",
      },
      attachments: [],
      breadcrumbs: [
        {
          text: "Страница курсов",
          path: "/methodist/courses",
        },
        {
          text: localStorage.getItem("course-name"),
          path: `/methodist/courses/${this.$route.params.id}`,
        },
      ],
    }
  },
  computed: {
    buttonDisabled() {
      return Str.empty(this.lectureData.name);
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    setDefaultData(info) {
      this.lectureData.name = info.name;
      this.lectureData.content = info.content;
      this.attachments = info.attachments || [];
    },
    previewHandler() {
      if (this.loading) return;

      localStorage.setItem(
        "lecture-preview-data",
        JSON.stringify({
          ...this.lectureData,
          attachments: this.attachments,
          fromPage: this.$route.path,
        })
      );

      this.$router.push(`/methodist/courses/${this.$route.params.id}/preview`);
    },
    async uploadFile(file) {
      const fd = new FormData();
      fd.append("the_file", file);

      this.$nprogress.start();

      try {
        const { data: res } = await this.$axios.post("/constructor/attachment", fd);

        this.attachments.push(res);
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.$nprogress.done();
      }
    },
    removeFile(fileId) {
      this.attachments = this.attachments.filter(({ id }) => id !== fileId);
    },
    cancelHandler() {
      if (this.loading) return;
      
      localStorage.removeItem("lecture-preview-data");
      this.$router.push(`/methodist/courses/${this.$route.params.id}`);
    },
  },
}