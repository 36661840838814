<template>
  <div class="up-add-edit-lecture">
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <div class="up-add-edit-lecture__form-wrapper">
      <el-button
        class="up-add-edit-lecture__remove-btn"
        type="text"
        @click="removeModalVisible = true"
      >
        Удалить лекцию
      </el-button>
      <LectureForm
        v-model="lectureData"
        :attachments="attachments"
        @upload-file="uploadFile"
        @remove-file="removeFile"
      />
    </div>
    <footer class="up-add-edit-lecture__footer">
      <el-button v-loading="loading" type="danger" @click="cancelHandler">
        Отменить
      </el-button>
      <el-button v-loading="loading" @click="previewHandler">
        Предпросмотр
      </el-button>
      <el-button
        v-loading="loading"
        type="success"
        @click="editHandler"
        :disabled="buttonDisabled || loading"
      >
        Сохранить
      </el-button>
    </footer>
    <AppSimpleModal
      :visible.sync="removeModalVisible"
      title="Удаление лекции"
      @action="removeLectureHandler"
      @close="removeModalVisible = false"
    >
      Вы уверены, что хотите удалить лекцию? Это действие нельзя будет отменить!
    </AppSimpleModal>
  </div>
</template>

<script>
import AppSimpleModal from "@/components/AppSimpleModal";

import addEditLectureMixin from "@/mixins/methodist/add-edit-lecture.mixin";

export default {
  name: "EditLecturePage",
  mixins: [addEditLectureMixin],
  components: { AppSimpleModal },
  data: () => ({
    removeModalVisible: false,
    loading: true,
  }),
  methods: {
    async fetchLectureData() {
      this.$nprogress.start();

      const cid = this.$route.params.id;
      const lid = this.$route.params.lid;

      try {
        const { data } = await this.$axios.get(
          `/constructor/course/${cid}/lesson/${lid}`
        );

        this.setDefaultData(data);
      } catch (e) {
        //
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async editHandler() {
      if (this.loading) return;

      this.$nprogress.start();
      this.loading = true;

      const attachments = this.attachments.map((a) => ({ id: a.id }));
      const cid = this.$route.params.id;

      try {
        await this.$axios.post(`/constructor/course/${cid}/lesson`, {
          id: this.$route.params.lid,
          type: "lecture",
          ...this.lectureData,
          attachments,
        });

        this.$message({
          message: "Поздравляем! Лекция успешно отредактирована.",
          type: "success",
        });

        this.$router.push(`/methodist/courses/${this.$route.params.id}`);
        localStorage.removeItem("lecture-preview-data");
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async removeLectureHandler() {
      const cid = this.$route.params.id;
      const lid = this.$route.params.lid;

      try {
        await this.$axios.delete(`/constructor/course/${cid}/lesson/${lid}`);

        this.$message({
          message: "Поздравляем! Лекция успешно удалена.",
          type: "success",
        });

        this.$router.push(`/methodist/courses/${cid}`);
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText("Редактирование лекции");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem("lecture-preview-data"));

    this.breadcrumbs.push({ text: "Редактирование лекции" });

    if (info) {
      this.loading = false;
      this.setDefaultData(info);
      return;
    }

    this.fetchLectureData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/courses/show/new-edit-lecture.scss";
</style>