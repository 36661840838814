export default {
  methods: {
    showErrorMessage(e) {
      if (!e.response && !e.response.data) return;

      this.$message({
        message: e.response.data.errorMessage,
        type: "error",
      });
    }
  },
}