<template>
  <el-form class="up-lecture-form">
    <el-form-item label="Название лекции" label-position="left">
      <el-input
        v-model="lectureData.name"
        placeholder="Введите название лекции"
      />
    </el-form-item>
    <el-form-item>
      <el-upload
        action="#"
        :auto-upload="false"
        :on-change="addFile"
        :show-file-list="false"
      >
        <el-button type="primary">Добавить pdf/doc файлы</el-button>
      </el-upload>
      <div
        v-for="a in attachments"
        :key="a.id"
        class="up-lecture-form__file-item"
      >
        <i class="el-icon-document" />
        <p>{{ a.name }}</p>
        <i
          class="el-icon-close"
          @click="$emit('remove-file', a.id)"
          role="button"
          aria-label="Удаление файла"
        />
      </div>
    </el-form-item>
    <el-form-item>
      <VueEditor v-model="lectureData.content" placeholder="Описание" />
    </el-form-item>
  </el-form>
</template>

<script>
import { VueEditor } from "vue2-editor";

const allowedMimes = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/pdf",
];

export default {
  name: "LectureForm",
  components: { VueEditor },
  props: {
    value: null,
    attachments: Array,
  },
  computed: {
    lectureData: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    addFile(file) {
      const isSuitable = allowedMimes.find((m) => m.includes(file.raw.type));

      if (isSuitable) {
        this.$emit("upload-file", file.raw);
        return;
      }

      this.$message({
        message: "Добавление файла с таким типом невозможно",
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/courses/show/lecture-form.scss";
</style>