<template>
  <el-breadcrumb separator="—">
    <template v-for="(b, i) in breadcrumbs">
      <el-breadcrumb-item v-if="b.path" :to="{ path: b.path }" :key="i">
        {{ b.text }}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-else :key="i">{{ b.text }}</el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'AppBreadcrumbs',
  props: {
    breadcrumbs: Array,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/app-breadcrumbs.scss";
</style>